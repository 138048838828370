import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/BlogLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This post is not about how to use Siesta for unit testing, more a quick guide on how to
get setup using PhatomJS to run your tests from Atom or Sublime.`}</p>
    <p>{`The main benefit to using this over the standard web ui is speed. From a keyboard shortcut you can have your test pass or fail within a second.`}</p>
    <h3>{`Atom`}</h3>
    <p>{`My current IDE of choice so i'll go over the setup for this one first.`}</p>
    <p>{`Firstly `}<a parentName="p" {...{
        "href": "https://github.com/noseglid/atom-build"
      }}>{`install this package`}</a>{` which allows you to build your current project directly from atom.`}</p>
    <p>{`Once you have done that all you no need to do is create a .atom-build.json file which tells the package how to build your project.`}</p>
    <p>{`Example .atom-build.json file.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "cmd": "PATH_TO_SIESTA/bin/phantomjs",
  "args": [ "LOCALHOST/test.html", "--filter {FILE_ACTIVE_NAME}"],
  "sh": true
}
`}</code></pre>
    <p>{`By using --filter we are telling PhantomJS to only run the test we are looking at within our IDE.`}</p>
    <h3>{`Sublime`}</h3>
    <p>{`Luckily Sublime supports build systems out of the box so no packages are needed to be installed.`}</p>
    <p>{`To create a new build system go tools > build systems > new build system.`}</p>
    <p>{`Example siesta.sublime-build.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "cmd" : ["PATH_TO_SIESTA/bin/phantomjs LOCALHOST/test.html --filter $file_name"],
  "shell": true
}
`}</code></pre>
    <p>{`Now you just need to ensure you are using this build system for your project and you can now run a single test just like in Atom.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      